
import './offer-ribbon.scss';

customElements.define('offer-ribbon', class extends HTMLElement {
  constructor() {
    super();

    this._debugMode = true;
    this._translations = translations.webComponents.offerRibbon;

    this._shadowRoot = this.attachShadow({mode: 'open'});
    const tpl = document.querySelector('#wc-tpl-offer-ribbon');
    if (!tpl || !tpl.content) return; // IE bugfix
    let clonedTpl = tpl.content.cloneNode(true);
    clonedTpl.querySelector('div').innerHTML = this._labelText;

    this._shadowRoot.appendChild(clonedTpl);
  }

  static get observedAttributes() {
    return ['type'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._shadowRoot.querySelector('div')) return; // IE bugfix
    this._shadowRoot.querySelector('div').innerHTML = this._labelText;
  }

  disconnectedCallback() {
    this._shadowRoot = null;
    this._translations = null;
  }

  get _type() {
    return this.getAttribute('type');
  }

  get _labelText() {
    switch(this._type) {
      case '1': return this._translations.types.topseller;
      case '2': return this._translations.types.topOffer;
      case '3': return this._translations.types.topPrice;
      default: return '';
    }
  }

  _remove() {
    this.parentElement.removeChild(this);
  }
});